import React, { useState, useEffect, useRef } from 'react';
import { Formik, Form } from 'formik';
import FormController from "../common/FormController"
import AIService from "../../services/AIService";
import { Button, Card, Col, Row, Modal, Tooltip, OverlayTrigger, Spinner } from "@themesberg/react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFileImage, faCamera } from '@fortawesome/free-solid-svg-icons';
import moment from "moment"
import { ProductImageCarouselWidget, ProductImageViewWidget } from "../../../components/Widgets";
import PermissionsCheck from '../../../auth/PermissionCheck';
import { scopes } from '../../../auth/PermissionsMap';
import EstablishmentsService from "../../services/EstablishmentsService";

const SiteImages = (props) => {

    const handleCloseModalAdd = () => setShowModalAdd(false);
    const handleCloseModalView = () => setShowModalView(false);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalView, setShowModalView] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);
    const [productImages, setProductImages] = useState([]);
    const [isSubmitting, setSubmitting] = useState(false);
    const initialValues = {
        filesToUpload: []
    }
    const GPTError = "The health mark is not clearly visible."
    const GPTCountryError = "The health mark was found but the incorrect Country was selected."
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    async function onSubmit(values, { resetForm }) {
        resetForm({});
        setSubmitting(true);
        try {
            const formData = new FormData();
            // Populate formData with the values
            for (const value in values) {
                formData.append(value, values[value]);
            }
            for (let i = 0; i < values.filesToUpload.length; i++) {
                formData.append('ImageFile', values.filesToUpload[i]);
            }

            const response = await AIService.getSiteHealthMarkResult(formData);
            console.log("response", response.data);
            const approvalNumber = response.data.toString();
            if (approvalNumber == "Error") {
                props.setErrorMessage(GPTError);
            }
            else {
                const EstablishmentsDetails = await fetchOptions(approvalNumber);
                const selectedEstablishment = [{
                    establishmentNumber: EstablishmentsDetails.approvalNumber,
                    siteName: EstablishmentsDetails.name,
                    tracesId: EstablishmentsDetails.operatorId
                }];
                let isoCode = EstablishmentsDetails.approvalNumber?.slice(0, 2) || "";
                const countryCode = props.countryId.label.match(/\(([^)]+)\)/)?.[1] || "";
                if (countryCode === isoCode) {
                    props.setEstablishmentsDetails(EstablishmentsDetails);
                    props.setEstablishmentSelected(selectedEstablishment);
                    props.setErrorMessage(null);
                }
                else {
                    props.setErrorMessage(GPTCountryError);
                }
            }
        } catch (error) {
            console.error("An error occurred", error);
            if (!mountedRef.current) return; 
            props.setErrorMessage(GPTError);
        } finally {
            if (mountedRef.current) {
                setSubmitting(false);
                handleCloseModalAdd();
            }
        }
    }

    const fetchOptions = async (approvalNumber) => {
        if (approvalNumber) {
            const response = await EstablishmentsService.getEstablishmentsDetailsByApprovalNumber(approvalNumber);
            console.log("response.data[0]", response.data[0]);
            return response.data[0];
        }
        return null;
    };


    let filesImages = { icon: faFileImage, colour: 'text-purple', extensions: ["jpg", "png", "bmp", "tiff"] };

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
    }

    function formattedDate(date) {
        var newDate = moment(date).format("Do MMM YYYY");
        if (newDate === "Invalid date") {
            return "";
        }
        return newDate;
    }

    function getLinkText(file, fileExt) {
        var fileName = file.filename.substring(37);
        return (
            <div className='text'>
                <a href={file.fileUrl} >
                    <FontAwesomeIcon icon={fileExt.icon} className={`mx-auto ${fileExt.colour} `} />
                    <span className="d-block fs-7">{fileName}</span>
                    <small><i>{formatBytes(file.size, null)}. Added {formattedDate(file.CreatedDate)} by {file.userFullName}</i></small>
                </a>
            </div>
        );
    }



    const files = productImages.map(file => (
        <li key={file.path} className="dropzone-upload" >
            {
                <>{getLinkText(file, filesImages)}</>
            }
        </li>
    ));
    return (
        <>
            {/* <PermissionsCheck errorProps={{ disabled: true }} scopes={[scopes.productdetail.create]}> */}
                <div className=' my-2'>
                    <OverlayTrigger placement="top" trigger={['hover', 'focus']} overlay={<Tooltip>Take a photo of a product with the establishment mark visible so we can automatically find your establishment.</Tooltip>}>
                        <Button onClick={() => setShowModalAdd(true)} variant="light" size="sm" className="py-2 btn-block">
                            <FontAwesomeIcon icon={faCamera} className="me-2" size="sm" />Upload product image
                        </Button>
                    </OverlayTrigger>
                    {productImages.length >= 1 && (
                        <Button onClick={() => setShowModalView(true)} variant="light" size="sm" className="text-dark me-2 float-end text-end">
                            <FontAwesomeIcon icon={faPlus} className="me-2" />
                            <span>Edit</span>
                        </Button>
                    )}
                </div>
            {/* </PermissionsCheck> */}

            {/* //Modal - Upload */}
            <Modal size="md" as={Modal.Dialog} centered show={showModalAdd} onHide={handleCloseModalAdd}>
                <Modal.Header>
                    <Modal.Title className="h6">Upload product image</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseModalAdd} />
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                    >
                        {(formikProps) => (
                            <Form>
                                <Row className="mt-0">
                                    <Col md={12}>
                                        <FormController
                                            control="multipleFileUpload"
                                            label="Take a picture or select an image to upload"
                                            name="filesToUpload"
                                            accept="image/*;capture=camera"
                                            className="mb-3"
                                            imageUploader
                                            multiple={false}
                                        />

                                        {isSubmitting && (
                                            <>
                                                <Spinner animation="border" variant="primary" size="sm" className="me-1" />
                                                Please wait...
                                            </>
                                        )}
                                        <div className="form-group mt-3">
                                            <Button className="btn-block" type="submit" disabled={isSubmitting || formikProps.values.filesToUpload.length <= 0}>{isSubmitting ? "Please wait..." : "Upload"}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik >
                </Modal.Body>
            </Modal>

            {/* //Modal - View */}
            <Modal size="md" as={Modal.Dialog} centered show={showModalView} onHide={handleCloseModalView}>
                <Modal.Header>
                    <Modal.Title className="h6">All images</Modal.Title>
                    <Button variant="close" aria-label="Close" onClick={handleCloseModalView} />
                </Modal.Header>
                <Modal.Body>
                    {/* //Image list */}
                    {files.length > 0 ? (
                        <ProductImageViewWidget
                            title="Select product photo(s)"
                            productImages={productImages}
                        />
                    )
                        :
                        (
                            <div className="text-center pt-4">
                                <h5 className="text-gray-600">No product images<br /> have been uploaded yet</h5>
                            </div>
                        )}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SiteImages;