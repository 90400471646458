import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, Card, Col } from "@themesberg/react-bootstrap";
import { FieldArray, Form, Formik, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { IsReadOnlyPermissions } from '../../../../auth/PermissionCheck';
import { scopes } from '../../../../auth/PermissionsMap';
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import CompositeService from "../../../services/CompositeService";
import { default as ProductDataService, default as ProductService } from "../../../services/ProductService";
import ZoneService, { zoneSpeciesGroups } from '../../../services/ZoneService';
import { BackButton, SubmissionPage } from '../../CertificateForms/CertificateFormComponents';
import { GetCertificateFormVersion } from '../../CertificateForms/CertificateFormFunctions';
import FormController from "../../common/FormController";
import { MultiSelectField } from "../../common/MultiSelect";
import SpeciesTable from "../Components/SpeciesTable";
import * as Composite8350Labels from "./Composite8350Labels";
import ColdStoreSelect from '../../../../components/EstablishmentSelect/ColdStoreSelect';
import DairyEstablishmentSelect from '../../../../components/EstablishmentSelect/DairyEstablishmentSelect';
import FisherySelect from '../../../../components/EstablishmentSelect/FisherySelect';
import BivalveSelect from '../../../../components/EstablishmentSelect/BivalveSelect';
import ActivityService from '../../../services/ActivityService';
import EggEstablishmentSelect from '../../../../components/EstablishmentSelect/EggEstablishmentSelect';

import { validationSchema } from './Composite8350Validation';

const Composite8350AddPage = (props) => {
    const certificateNumber = 8350;
    const [isSubmitting, setSubmitting] = useState(false);
    const { id, attestationId } = useParams();
    const [errors, setErrors] = useState(null);
    const [isGEFS, setIsGEFS] = useState(true);
    const [editing, setEditing] = useState(false);
    const [readOnly, setReadOnly] = useState(false);
    const [ready, setReady] = useState(false);
    const [listsReady, setListsReady] = useState(false);
    const [product, SetProduct] = useState([]);
    const [productId] = useState(props.match.params.id);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [countrys, SetCountrys] = useState([]);
    const [doesContainMeat, setContainsMeat] = useState(false);
    const [doesContainDairy, setContainsDairy] = useState(false);
    const [doesContainColostrum, setContainsColostrum] = useState(false);
    const [doesContainEgg, setContainsEgg] = useState(false);
    const [doesContainFishery, setContainsFishery] = useState(false);
    const [productDetailCountry, SetCountry] = useState([]);
    const [eggZones, setEggZones] = useState([]);
    const [dairyZones, setDairyZones] = useState([]);
    const [dairyVaccZones, setDairyVaccZones] = useState([]);
    const [bivalveMolluscsZones, SetBivalveMolluscsZones] = useState([]);
    const [memberStates, SetMemberStates] = useState([]);
    const [controlledRiskZones, SetControlledRisk] = useState([]);
    const [negligbleRiskZones, SetNegligbleRisk] = useState([]);
    const [apiValues, SetApiValues] = useState('');
    const [productDetail, setProductDetail] = useState(null);
    const [formVersion] = useState(GetCertificateFormVersion(8350));
    const [checkboxOptions, SetCheckboxOptions] = useState([]);
    const [radioOptions, SetRadioOptions] = useState([]);
    const [isDairyCountryChanging, setDairyCountryChanging] = useState(false);
    const [speciesZonesTable, setSpeciesZoneTables] = useState(zoneSpeciesGroups);

    let title = (editing == true ? "Edit " : "New") + " Composite (#8350) Information"

    const breadcrumbData = {
        item1: { name: 'Products', link: Routes.Products.path },
        item2: { name: product.productName, link: Routes.Product.path.replace(":id", productId) },
        item3: { name: 'Composite 8350', link: Routes.Composite8350.path },
    };

    let compositeLabels = Composite8350Labels;

    const initialValues = {
        productId: props.match.params.id,
        coldStore: apiValues?.coldStore ?? '',
        coldStoreEstablishments: apiValues?.coldStoreEstablishments ?? [],
        cuttingPlantEstablishments: apiValues?.cuttingPlantEstablishments ?? [],
        cuttingPlant: apiValues?.cuttingPlant ?? '',
        containsMeat: apiValues?.containsMeat ?? false,
        containsDairy: apiValues?.containsDairy ?? false,
        containsFishery: apiValues?.containsFishery ?? false,
        containsEgg: apiValues?.containsEgg ?? false,
        meatData:
        {
            abattoirEstablishments: apiValues?.meatData?.abattoirEstablishments ?? [],
            abattoir: apiValues?.meatData?.abattoir ?? '',
            meatZoneOriginates: apiValues?.meatData?.meatZoneOriginates ?? [],
            originatesFrom: [],
            originatesFrom1: apiValues?.meatData?.originatesFrom1 ?? false,
            originatesFrom2: apiValues?.meatData?.originatesFrom2 ?? false,
            originatesFrom3: apiValues?.meatData?.originatesFrom3 ?? false,
            BseControlled: apiValues?.meatData?.bseControlled ?? 0,
            BseRiskCategoryNegligible: apiValues?.meatData?.bseRiskCategoryNegligible ?? false,
            BseRiskCategoryControlled: apiValues?.meatData?.bseRiskCategoryControlled ?? false,
            BseRiskCategoryUndetermined: apiValues?.meatData?.bseRiskCategoryUndetermined ?? false,
            BseControlled4: apiValues?.meatData?.bseControlled4 ?? 0,
            BseUndetermined4: apiValues?.meatData?.bseUndetermined4 ?? 0,
            BseRiskCategoryNegligibleStatements: apiValues?.meatData?.bseRiskCategoryNegligibleStatements ?? [],
            BseRiskCategoryNegligibleStatements1: apiValues?.meatData?.bseRiskCategoryNegligibleStatements1 ?? [],
            BseRiskCategoryNegligibleStatements2: apiValues?.meatData?.bseRiskCategoryNegligibleStatements2 ?? [],
            BseRiskCategoryControlledStatements: apiValues?.meatData?.bseRiskCategoryControlledStatements ?? [],
            BseRiskCategoryControlledCStatements: apiValues?.meatData?.bseRiskCategoryControlledCStatements ?? [],
            BseRiskCategoryControlled4Statements: apiValues?.meatData?.bseRiskCategoryControlled4Statements ?? [],
            BseControlledC: apiValues?.meatData?.bseControlledC ?? 0,
            BseRiskCategoryUndeterminedStatements: apiValues?.meatData?.bseRiskCategoryUndeterminedStatements ?? [],
            BseRiskCategoryUndetermined4Statements: apiValues?.meatData?.bseRiskCategoryUndetermined4Statements ?? [],
            containsBOV: false,
            containsOVI: false,
            containsPOR: false,
            containsPOU: false,
            containsRUF: false,
            containsRUW: false,
            containsSUW: false,
            containsWGB: false,
            containsGBM: false,
            containsRAT: false,
            containsSUF: false,
            speciesList: apiValues?.meatData?.speciesList ?? [],


        },
        dairyData:
        {
            dairyA_1: apiValues?.dairyData?.dairyA_1 ?? false,
            dairyA_2: apiValues?.dairyData?.dairyA_2 ?? false,
            dairyA_3: apiValues?.dairyData?.dairyA_3 ?? false,
            dairyACountryOrigin: apiValues?.dairyData?.dairyACountryOrigin ?? [],
            dairyA2CountryOrigin: apiValues?.dairyData?.dairyA2CountryOrigin ?? [],
            dairyBZoneOriginates: apiValues?.dairyData?.dairyBZoneOriginates ?? [],
            dairyAApprovalNumber: apiValues?.dairyData?.dairyAApprovalNumber ?? '',
            dairyAapprovalNumberEstablishments: apiValues?.dairyData?.dairyAapprovalNumberEstablishments ?? [],
            dairyBOriginatesIn: apiValues?.dairyData?.dairyBOriginatesIn ?? [],
            dairyCMilkObtainedFrom: apiValues?.dairyData?.dairyCMilkObtainedFrom ?? false,
            dairyCMilkType1: apiValues?.dairyData?.dairyCMilkType1 ?? false,
            dairyCMilkType2: apiValues?.dairyData?.dairyCMilkType2 ?? false,
            dairyCMilkType3: apiValues?.dairyData?.dairyCMilkType3 ?? false,
            dairyCMilkType4: apiValues?.dairyData?.dairyCMilkType4 ?? false,
            dairyCMilkType5: apiValues?.dairyData?.dairyCMilkType5 ?? false,
            dairyCStatements: parseInt(apiValues?.dairyData?.dairyCStatements, 10) ?? 0,
            dairyC_1: parseInt(apiValues?.dairyData?.dairyC_1, 10) ?? 0,
            dairyC_2: parseInt(apiValues?.dairyData?.dairyC_2, 10) ?? 0,
            dairyD: apiValues?.dairyData?.dairyD ?? false,
            dairyC_1_6: parseInt(apiValues?.dairyData?.dairyC_1_6, 10) ?? 0
        },
        fisheryEstablishmentCode: apiValues?.fisheryEstablishmentCode ?? '',
        fisheryEstablishmentCodeEstablishments: apiValues?.fisheryEstablishmentCodeEstablishments ?? [],
        fisheryCountryOrigin: apiValues?.fisheryCountryOrigin ?? [],
        fisheryAquaculture: apiValues?.fisheryAquaculture ?? false,
        fisheryWildOrigin: apiValues?.fisheryWildOrigin ?? false,
        bivalveMolluscsEstablishmentCode: apiValues?.bivalveMolluscsEstablishmentCode ?? '',
        bivalveMolluscsEstablishmentCodeEstablishments: apiValues?.bivalveMolluscsEstablishmentCodeEstablishments ?? [],
        bivalveMolluscsCountryOrigin: apiValues?.bivalveMolluscsCountryOrigin ?? [],
        bivalveMolluscsAquaculture: apiValues?.bivalveMolluscsAquaculture ?? false,
        bivalveMolluscsWildOrigin: apiValues?.bivalveMolluscsWildOrigin ?? false,
        eggData: {
            eggEstablishments: apiValues?.eggData?.eggEstablishments ?? [],
            eggOriginatesFrom1: apiValues?.eggData?.eggOriginatesFrom1 ?? false,
            eggOriginatesFrom2: apiValues?.eggData?.eggOriginatesFrom2 ?? false,
            eggCountryOrigin: apiValues?.eggData?.eggCountryOrigin ?? [],
            eggStatementsA: apiValues?.eggData?.eggStatementsA ?? 0,
            egg_A_2: apiValues?.eggData?.egg_A_2 ?? 0,
            egg_A_2_1: apiValues?.eggData?.egg_A_2_1 ?? 0,
            egg_A_2_3: apiValues?.eggData?.egg_A_2_3 ?? 0,
            egg_A_2_4: apiValues?.eggData?.egg_A_2_4 ?? 0,
            egg_A_2_5: apiValues?.eggData?.egg_A_2_5 ?? 0,
            eggStatementsB: apiValues?.eggData?.eggStatementsB ?? 1,
            egg_B_2: apiValues?.eggData?.egg_B_2 ?? 0,
            egg_B_2_1: apiValues?.eggData?.egg_B_2_1 ?? 0,
            egg_B_2_4: apiValues?.eggData?.egg_B_2_4 ?? 0,
        }
    }

    const PrettyPrintJson = ({ data }) => (<div><pre>{
        JSON.stringify(data, null, 2)}</pre></div>);

    function BuildZoneTable(speciesGroupName, treatmentType) {
        //Find species group to update in the table
        const speciesGroupNameFound = speciesZonesTable.find(x => x.speciesGroupName === speciesGroupName && x.treatmentType === treatmentType);

        //Get zones for group if not already populated
        if (speciesGroupNameFound != undefined && speciesGroupNameFound != null && treatmentType != null && treatmentType != undefined) {
            try {
                //member states
                var memberStatesStructured = memberStates.map((memberState) => ({
                    "id": memberState.id,
                    "displayName": memberState.displayName,
                    "countryName": memberState.countryName,
                    "isoCode": memberState.isoCode,
                    "isMemberState": true,
                    "isCountryOfOrigin": memberState.countryName === productDetailCountry.countryName ? true : false,
                    "isRiskExempt": false,
                    "isBseNegligibleRisk": negligbleRiskZones.filter(f => f.countryName === memberState.countryName).length > 0 ? true : false,
                    "isBseControlledRisk": controlledRiskZones.filter(f => f.countryName === memberState.countryName).length > 0 ? true : false,
                    "isBseUndeterminedRisk": !controlledRiskZones.filter(f => f.countryName === memberState.countryName).length > 0 && !negligbleRiskZones.filter(f => f.countryName === memberState.countryName).length > 0 ? true : false,

                }));

                //species gorups
                ZoneService
                    .getAllByGroup(speciesGroupNameFound.zoneGroupName)
                    .then(response => {
                        var options = response.data.map(d => ({
                            "id": d.id,
                            "displayName": d.displayName,
                            "countryName": d.countryName,
                            "isoCode": d.isoCode,
                            //Flags
                            "isMemberState": memberStates.filter(f => f.countryName === d.countryName).length > 0 ? true : false,
                            "isCountryOfOrigin": d.countryName === productDetailCountry.countryName ? true : false,
                            "isRiskExempt": memberStates.filter(f => f.countryName === d.countryName).length <= 0 && d.countryName != productDetailCountry.countryName ? true : false,
                            "isBseNegligibleRisk": negligbleRiskZones.filter(f => f.countryName === d.countryName).length > 0 ? true : false,
                            "isBseControlledRisk": controlledRiskZones.filter(f => f.countryName === d.countryName).length > 0 ? true : false,
                            "isBseUndeterminedRisk": !controlledRiskZones.filter(f => f.countryName === d.countryName).length > 0 && !negligbleRiskZones.filter(f => f.countryName === d.countryName).length > 0 ? true : false,

                        }))

                        //Add member states to the found contries list
                        var data = null;
                        data = memberStatesStructured.concat(options);
                        data.sort((a, b) => a.displayName.localeCompare(b.displayName));

                        //Populate SpeicesZonesTable with the data
                        setSpeciesZoneTables(prevState => {
                            const newState = prevState.map(x => {
                                if (x.speciesGroupName === speciesGroupName && x.treatmentType === treatmentType) {
                                    return { ...x, zonesArr: data }
                                }
                                return x;
                            })
                            return newState;
                        });
                    }, [])
                    .catch(e => {
                        console.log(e);
                    })
            } catch (e) {
                console.error(e);
            }
        }
    }

    function onSubmit(values, { resetForm }) {
        setSubmitting(true);
        setTimeout(() => {
            let combinedNegligibleStatements = [];
            if (values.meatData && typeof values.meatData.BseRiskCategoryNegligibleStatements1 === 'number') {
                combinedNegligibleStatements.push(values.meatData.BseRiskCategoryNegligibleStatements1.toString());
            }
            else if (Array.isArray(values.meatData.BseRiskCategoryNegligibleStatements1)) {
                combinedNegligibleStatements = combinedNegligibleStatements.concat(values.meatData.BseRiskCategoryNegligibleStatements1);
            }
            if (Array.isArray(values.meatData.BseRiskCategoryNegligibleStatements2)) {
                combinedNegligibleStatements = combinedNegligibleStatements.concat(values.meatData.BseRiskCategoryNegligibleStatements2);
            }
            values.meatData.BseRiskCategoryNegligibleStatements = combinedNegligibleStatements;
            let submitData = { ...values, version: formVersion };
            var data = JSON.stringify(values, null, 2);
            if (!values.containsMeat) {
                submitData.meatData = null
            }
            if (!values.containsDairy) {
                submitData.dairyData = null
            }
            if (!values.containsEgg) {
                submitData.eggData = null
            }
            if (apiValues.length === 0) {
                CompositeService.add(submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.logCertForm(
                            'Composite 8350',
                            8350,
                            'Create',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({});
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            else {
                CompositeService.update(productId, submitData)
                    .then(async response => {
                        //Create Activity Log
                        await ActivityService.logCertForm(
                            'Composite 8350',
                            8350,
                            'Update',
                            isGEFS,
                            productId,
                            product.productName
                        );
                        resetForm({});
                    })
                    .catch(e => {
                        setSubmitting(false);
                        setErrors(e.response.data);
                    });
            }
            setFormSubmitted(true);
        }, 1000);
    }

    const allMeatSpecies = [
        { name: 'BOV' },
        { name: 'OVI' },
        { name: 'POR' },
        { name: 'POU' },
        { name: 'RAT' },
        { name: 'RUF' },
        { name: 'RUW' },
        { name: 'SUF' },
        { name: 'SUW' },
        { name: 'GBM' },
    ]

    const ChangeFormValues = () => {
        const { values, setFieldValue } = useFormikContext();
        useEffect(() => {
            if (ready) {

                //Auto select main categories
                setFieldValue("containsMeat", doesContainMeat);
                setFieldValue("containsDairy", doesContainDairy);
                setFieldValue("containsEgg", doesContainEgg);
                setFieldValue("containsFishery", doesContainFishery);

                //Meat Data
                if (doesContainMeat) {

                    let selectedSpecies = values.meatData.speciesList;
                    const bseRiskSpecies = ["BOV", "OVI", "RUW", "RUF"];

                    //Get checked meat species groups
                    values.meatData.speciesList.forEach((speciesGroup, specieGroupIndex) => {
                        var groupName = speciesGroup.speciesGroupName;
                        setFieldValue(`meatData.contains${groupName}`, true);

                        //Get zone data on load for each species group
                        if (speciesZonesTable.filter(x => x.zonesArr.length > 0).length >= 0) {
                            speciesGroup.speciesData.forEach((speciesData, speciesDataIndex) => {
                                speciesData.countryOfOrigin.forEach((countryOfOrigin2, countryOfOriginIndex) => {
                                    var matchCountryFromZoneArr = speciesZonesTable.find(x => x.treatmentType == speciesData.treatment && x.speciesGroupName == speciesGroup.speciesGroupName);
                                    var countryFilterZoneData = matchCountryFromZoneArr?.zonesArr.find(x => x.id === countryOfOrigin2.id);
                                    if (countryFilterZoneData != undefined) {
                                        selectedSpecies[specieGroupIndex].speciesData[speciesDataIndex].countryOfOrigin[countryOfOriginIndex] = countryFilterZoneData;
                                    }
                                });
                            });
                        };
                    });

                    //Pots of data (i.e. species and treatment type zones / originates from zones)
                    const selectedAllZones = selectedSpecies
                        .map(list => list.speciesData)
                        .map(filteredPosts => filteredPosts.map(post => post.countryOfOrigin).flat())
                        .flat();
                    const selectedTreatmentAZones = selectedSpecies
                        .map(list => list.speciesData.filter(data => data.treatment == "A"))
                        .map(filteredPosts => filteredPosts.map(post => post.countryOfOrigin).flat())
                        .flat();

                    const selectedBseZones = selectedSpecies
                        .map(list => list.speciesData.filter(data =>
                            bseRiskSpecies.includes(list.speciesGroupName)))
                        .map(filteredPosts => filteredPosts.map(post => post.countryOfOrigin).flat())
                        .flat();

                    //Find countries in risk exempt groups
                    const zonesOriginates3 = selectedTreatmentAZones.filter(x => x.isRiskExempt);

                    //Check selected zones flags to set form values
                    let hasOriginates1 = selectedAllZones.find(x => x.isCountryOfOrigin) ? true : false;
                    let hasOriginates2 = selectedAllZones.find(x => x.isMemberState) ? true : false;
                    let hasOriginates3 = zonesOriginates3.find(x => x.isRiskExempt) ? true : false;
                    let hasBseNegligible = selectedBseZones.find(x => x.isBseNegligibleRisk) ? true : false;
                    let hasBseControlled = selectedBseZones.find(x => x.isBseControlledRisk) ? true : false;
                    let hasBseUndetermined = selectedBseZones.find(x => x.isBseUndeterminedRisk) ? true : false;

                    //Set Originates from fields
                    setFieldValue('meatData.originatesFrom1', hasOriginates1);
                    setFieldValue('meatData.originatesFrom2', hasOriginates2);
                    setFieldValue('meatData.originatesFrom3', hasOriginates3);
                    if (hasOriginates3) {
                        updateMeatOriginatesLabel(zonesOriginates3);
                        values.meatData.meatZoneOriginates = zonesOriginates3;
                    } else {
                        updateMeatOriginatesLabel([]);
                    }
                    //Set BSE Risk Category fields
                    setFieldValue('meatData.BseRiskCategoryNegligible', hasBseNegligible);
                    setFieldValue('meatData.BseRiskCategoryControlled', hasBseControlled);
                    setFieldValue('meatData.BseRiskCategoryUndetermined', hasBseUndetermined);

                    //Clear fields where parent BSE Risk is deselected
                    if (!hasBseNegligible) {
                        values.meatData.BseRiskCategoryNegligibleStatements = [];
                        values.meatData.BseRiskCategoryNegligibleStatements1 = [];
                        values.meatData.BseRiskCategoryNegligibleStatements2 = [];
                    }
                    if (!hasBseControlled) {
                        values.meatData.BseRiskCategoryControlledStatements = [];
                        values.meatData.BseRiskCategoryControlledCStatements = [];
                    }
                    if (!values.meatData?.BseRiskCategoryControlledStatements) {
                        values.meatData.BseRiskCategoryControlled4Statements = [];
                    }
                    if (!hasBseUndetermined) {
                        values.meatData.BseRiskCategoryUndeterminedStatements = [];
                    }
                    if (!values.meatData?.BseRiskCategoryUndeterminedStatements) {
                        values.meatData.BseRiskCategoryUndetermined4Statements = [];
                    }
                }
                //Dairy data
                if (doesContainDairy) {
                    var selectedOriginDairy1 = [];
                    var selectedOriginDairy2 = [];
                    var selectedOriginDairy3 = [];
                    var selectedZonesOriginDairy = [];
                    var selectedZonesOriginDairyA = [];
                    var selectedZonesOriginDairyB = [];
                    if (values.dairyData.dairyA_1 == false && values.dairyData.dairyA_2 == false) {
                        compositeLabels.dairyBOriginatesInChoice[2].key = "a country with code …… …………….. authorised for entry into the Union of milk, colostrum, dairy products and colostrum based products in Annex XVII, Part 1, to Implementing Regulation (EU) 2021/404, where the country where the composite product was produced is also authorised, under the same conditions, for entry into the Union of milk, colostrum, dairy products and colostrum-based products and listed in Part 1 of that Annex;";
                        values.dairyData.dairyACountryOrigin = [];
                        selectedZonesOriginDairyA = [];
                        values.dairyData.dairyA2CountryOrigin = [];
                        selectedZonesOriginDairyB = [];
                        values.dairyData.dairyBOriginatesIn = values.dairyData.dairyBOriginatesIn.filter(item => item !== '1' && item !== '3');
                    } else {
                        if (values.dairyData.dairyA_1 == false) {
                            values.dairyData.dairyACountryOrigin = [];
                            selectedZonesOriginDairyA = [];
                        }
                        if (values.dairyData.dairyA_2 == false) {
                            values.dairyData.dairyA2CountryOrigin = [];
                            selectedZonesOriginDairyB = [];
                        }
                        updateDairyBOriginatesLabel(values.dairyData.dairyBZoneOriginates);
                    }

                    if (values.dairyData?.dairyCStatements != 1) {
                        setFieldValue('dairyData.dairyC_1', 0);
                        setFieldValue('dairyData.dairyC_1_6', 0);
                    }
                    if (values.dairyData?.dairyC_1 != 6) {
                        setFieldValue('dairyData.dairyC_1_6', 0);
                    }
                    if (values.dairyData?.dairyCStatements != 2) {
                        setFieldValue('dairyData.dairyC_2', 0);
                    }

                    if (!values.dairyData?.dairyCMilkObtainedFrom == true) {
                        setFieldValue('dairyData.dairyCMilkType1', false);
                        setFieldValue('dairyData.dairyCMilkType2', false);
                        setFieldValue('dairyData.dairyCMilkType3', false);
                        setFieldValue('dairyData.dairyCMilkType4', false);
                        setFieldValue('dairyData.dairyCMilkType5', false);
                        setFieldValue('dairyData.dairyCStatements', 0);

                        setFieldValue('dairyData.dairyC_1', 0);
                        setFieldValue('dairyData.dairyC_2', 0);
                        setFieldValue('dairyData.dairyC_1_6', 0);
                    }
                    if (values.dairyData?.dairyA_3 == true) {
                        selectedOriginDairy2.push('2');
                    }
                    if (values.dairyData?.dairyA_3 == false) {
                        values.dairyData.dairyBOriginatesIn = values.dairyData.dairyBOriginatesIn.filter(item => item !== '2');
                    }

                    values.dairyData.dairyACountryOrigin.forEach((entry) => {
                        if (entry != undefined && entry != null) {
                            const search = (text) => memberStates.filter(({ countryName }) => countryName.includes(text));
                            const result = search(entry.countryName);
                            if (result.find(f => f.countryName === entry.countryName)) {
                                selectedOriginDairy2.push("2");
                            }
                            else if (productDetailCountry.countryName != entry.countryName) {
                                selectedOriginDairy3.push("3");
                                selectedZonesOriginDairyA.push(entry);
                            }
                            if (productDetailCountry.countryName === entry.countryName) {
                                selectedOriginDairy1.push("1");
                            }
                        }
                    });
                    values.dairyData.dairyA2CountryOrigin.forEach((entry) => {
                        if (entry != undefined && entry != null) {
                            const search = (text) => memberStates.filter(({ countryName }) => countryName.includes(text));
                            const result = search(entry.countryName);
                            if (result.find(f => f.countryName === entry.countryName)) {
                                selectedOriginDairy2.push("2");
                            }
                            else if (productDetailCountry.countryName != entry.countryName) {
                                selectedOriginDairy3.push("3");
                                selectedZonesOriginDairyB.push(entry);
                            }
                            if (productDetailCountry.countryName === entry.countryName) {
                                selectedOriginDairy1.push("1");
                            }
                        }
                    });
                    if (selectedZonesOriginDairy != []) {
                        selectedZonesOriginDairy = selectedZonesOriginDairy.concat(selectedZonesOriginDairyA, selectedZonesOriginDairyB);
                        values.dairyData.dairyBZoneOriginates = selectedZonesOriginDairy;
                        updateDairyBOriginatesLabel(selectedZonesOriginDairy);
                    }
                    // Simplify setting the `dairyBOriginatesInX` properties based on the length of selectedOriginDairyX
                    let dairyBOriginatesIn1 = selectedOriginDairy1 ? selectedOriginDairy1.length > 0 : false;
                    let dairyBOriginatesIn2 = selectedOriginDairy2 ? selectedOriginDairy2.length > 0 : false;
                    let dairyBOriginatesIn3 = selectedOriginDairy3 ? selectedOriginDairy3.length > 0 : false;

                    // Simplify updating the dairyBOriginatesIn array based on `dairyBOriginatesInX` values
                    let originates = [];
                    if (dairyBOriginatesIn1) originates.push('1');
                    if (dairyBOriginatesIn2) originates.push('2');
                    if (dairyBOriginatesIn3) originates.push('3');

                    // Set the dairyBOriginatesIn property based on the originates array
                    values.dairyData.dairyBOriginatesIn = originates;
                    if (!doesContainColostrum) {
                        setFieldValue('dairyData.dairyD', false);
                    }   
                }

                //Egg Data
                if (doesContainEgg) {
                    if (!values?.eggData?.eggOriginatesFrom1 == true) {
                        { values.eggData.eggCountryOrigin = []; }
                    }
                    if (values.eggData?.eggStatementsA != 2) {
                        setFieldValue('eggData.egg_A_2', 0);
                        setFieldValue('eggData.egg_A_2_1', 0);
                        setFieldValue('eggData.egg_A_2_3', 0);
                        setFieldValue('eggData.egg_A_2_4', 0);
                        setFieldValue('eggData.egg_A_2_5', 0);
                    }
                    if (values.eggData?.egg_A_2 != 1) {
                        setFieldValue('eggData.egg_A_2_1', 0);
                    }
                    if (values.eggData?.egg_A_2 != 3) {
                        setFieldValue('eggData.egg_A_2_3', 0);
                    }
                    if (values.eggData?.egg_A_2 != 4) {
                        setFieldValue('eggData.egg_A_2_4', 0);
                    }
                    if (values.eggData?.egg_A_2 != 5) {
                        setFieldValue('eggData.egg_A_2_5', 0);
                    }

                    if (values.eggData?.eggStatementsB != 2) {
                        setFieldValue('eggData.egg_B_2', 0);
                        setFieldValue('eggData.egg_B_2_1', 0);
                        setFieldValue('eggData.egg_B_2_4', 0);
                    }


                    if (values.eggData?.egg_B_2 != 1) {
                        setFieldValue('eggData.egg_B_2_1', 0);
                    }
                    if (values.eggData?.egg_B_2 != 4) {
                        setFieldValue('eggData.egg_B_2_4', 0);
                    }

                }
            }
        }, [values.dairyData, values.eggData, values.meatData]
        );

        return null;
    };

    function updateMeatOriginatesLabel(zones) {
        const zonesString = zones.map(item => item.isoCode).join(", ");
        const labelKey = zonesString.length > 0 ?
            `a zone with code ${zonesString} authorised for entry into the Union of meat products not required to undergo a specific risk-mitigating treatment as set out in Annex XV to Commission Implementing Regulation (EU) 2021/404 with assigned treatment A, and the zone where the composite product was produced is also authorised for entry into the Union of meat products with assigned treatment A.` :
            "a zone with code …… …………….. authorised for entry into the Union of meat products not required to undergo a specific risk-mitigating treatment as set out in Annex XV to Commission Implementing Regulation (EU) 2021/404 with assigned treatment A, and the zone where the composite product was produced is also authorised for entry into the Union of meat products with assigned treatment A.";
        compositeLabels.meatOriginatesFromChoice[2].key = labelKey;
    }

    function updateDairyBOriginatesLabel(zones) {
        const transformedZones = zones.map(item => item.isoCode.substring(0, 2)).join(", ");
        const labelKey = transformedZones.length > 0 ?
            `a country with code ${transformedZones} authorised for entry into the Union of milk, colostrum, dairy products and colostrum based products in Annex XVII, Part 1, to Implementing Regulation (EU) 2021/404, where the country where the composite product was produced is also authorised, under the same conditions, for entry into the Union of milk, colostrum, dairy products and colostrum-based products and listed in Part 1 of that Annex;` :
            "a country with code …… …………….. authorised for entry into the Union of milk, colostrum, dairy products and colostrum based products in Annex XVII, Part 1, to Implementing Regulation (EU) 2021/404, where the country where the composite product was produced is also authorised, under the same conditions, for entry into the Union of milk, colostrum, dairy products and colostrum-based products and listed in Part 1 of that Annex;";
        compositeLabels.dairyBOriginatesInChoice[2].key = labelKey;
    }
    //Run on initialisation
    useEffect(() => {
        async function fetchListData() {
            if (!listsReady) {
                try {
                    //Get saved product data
                    const populateProductData = new Promise((resolve, reject) => {
                        resolve(
                            ProductService.getById(productId)
                                .then(response => {
                                    SetProduct(response.data.data);
                                    setIsGEFS(response.data.data.isGEFS);
                                }, [])
                                .catch(e => {
                                    console.log(e);
                                })
                        );
                    });

                    //Get product detail data
                    const populateProductDetailData = new Promise((resolve, reject) => {
                        resolve(
                            ProductDataService.getProductDetailByProductId(productId)
                                .then(response => {
                                    var res = response.data;
                                    SetCountry(res.countryOrigin);

                                    //nature of commodity - set checkboxes
                                    var natureList = [];
                                    if (!res.commodityNature.includes(',')) {
                                        natureList = res.commodityNature.trim();
                                    }
                                    else {
                                        natureList = res.commodityNature.split(',').map(item => item.trim());
                                    }
                                    setContainsMeat(natureList.includes("Meat product"));
                                    setContainsDairy(natureList.includes("Dairy product"));
                                    setContainsColostrum(natureList.includes("Colostrum-based product"));
                                    setContainsEgg(natureList.includes("Egg products"));
                                    setContainsFishery(natureList.includes("Aquaculture product") || natureList.includes("Wild origin product"));
                                })
                                .catch(e => {
                                    console.log(e);
                                })
                        );
                    });
                    const preprocessOptions = (options) => {
                        return options.map((option) => {
                            // Remove existing numbering from the key string
                            const keyWithoutNumbering = option.key.replace(/^\d+\.\s+/, '');
                            // Apply the new numbering based on the 'value' field
                            return {
                                ...option,
                                key: `${option.value}. ${keyWithoutNumbering}`
                            };
                        });
                    };

                    // Preprocess the options with the function
                    const processedOptions = preprocessOptions(compositeLabels.BseRiskCategoryNegligibleStatementsChoice);

                    // Filter the processed options for radio buttons and checkboxes
                    SetRadioOptions(processedOptions.filter(choice => parseInt(choice.value) === 1 || parseInt(choice.value) === 2));
                    SetCheckboxOptions(processedOptions.filter(choice => parseInt(choice.value) === 3 || parseInt(choice.value) === 4));
                    //Get all zone countries for lists by group
                    const populateZoneFishery = populateZone("8350_FISHERY_PRODUCTS", SetCountrys, null, null);
                    const populateZoneMemberStates = populateZone("ALL_MEMBER_STATES", SetMemberStates, null, null);
                    const populateZoneBseControlled = populateZone("ALL_BSE_CONTROLLED_RISK", SetControlledRisk, null, null);
                    const populateZoneBseNegligible = populateZone("ALL_BSE_NEGLIGBLE_RISK", SetNegligbleRisk, null, null);
                    const populateZoneBivalve = populateZone("8350_BIVALVEMOLLUSCS_PRODUCTS", SetBivalveMolluscsZones, null, null);
                    const populateZoneEgg = populateZone("ALL_EGG_PRODUCT", setEggZones, 'regionName', "GB-0");
                    const populateZoneDairy = populateZone("ALL_DAIRY_PRODUCT", setDairyZones, 'countryName', "GB-0");
                    const populateZoneDairyVacc = populateZone("8350_DAIRY_PRODUCT_VACC", setDairyVaccZones, 'countryName', "AE-0");

                    //Helper function for zones
                    function populateZone(group, setFunction, sortBy = 'displayName', priorityIsoCode = null) {
                        return new Promise((resolve, reject) => {
                            ZoneService.getAllByGroup(group)
                                .then(response => {
                                    let options = response.data.map(d => ({
                                        "id": d.id,
                                        "displayName": d.displayName,
                                        "countryName": d.countryName,
                                        "regionName": d.regionName,
                                        "isoCode": d.isoCode,
                                    }));

                                    // Sorting by provided attribute (displayName or countryName)
                                    options.sort((a, b) => a[sortBy]?.localeCompare(b[sortBy]));

                                    // If a priorityIsoCode is provided, adjust the sorting to prioritize it
                                    if (priorityIsoCode) {
                                        options.sort((x, y) => {
                                            return x.isoCode === priorityIsoCode ? -1 : y.isoCode === priorityIsoCode ? 1 : 0;
                                        });
                                    }

                                    setFunction(options);
                                    resolve();
                                })
                                .catch(e => {
                                    console.log(e);
                                    reject(e);
                                });
                        });
                    }

                    Promise.all([populateProductData, populateZoneFishery, populateZoneMemberStates, populateZoneBseControlled, populateZoneBseNegligible, populateZoneEgg, populateZoneDairy, populateZoneDairyVacc, populateZoneBivalve, populateProductDetailData]).then((responses) => {
                        setListsReady(true);
                    });
                } catch (e) {
                    console.error(e);
                }
            };

        }
        fetchListData();
    }, [ready]);

    //Get composite data after lists are loaded 
    useEffect(() => {
        if (!ready && listsReady) {
            //Get saved composite data
            CompositeService.getByProductId(productId)
                .then(response => {
                    if (response.data == "") {
                        setReady(true);
                        return;
                    }
                    setEditing(true);

                    if (response.data.meatData !== null) {
                        //Build zones for intial species groups
                        response.data.meatData?.speciesList.map((speciesGroup) => {
                            speciesGroup.speciesData.forEach((speciesData) => {
                                BuildZoneTable(speciesGroup.speciesGroupName, speciesData.treatment);
                            });

                        });

                        let statements1 = [];
                        let statements2 = [];
                        if (response.data.meatData?.bseRiskCategoryNegligibleStatements) {
                            const meatDataNegligibleStatements = response.data.meatData?.bseRiskCategoryNegligibleStatements;
                            if (meatDataNegligibleStatements) {
                                if (meatDataNegligibleStatements.includes("1") || meatDataNegligibleStatements.includes("2")) {
                                    statements1 = meatDataNegligibleStatements.filter(val => val === "1" || val === "2");
                                }
                                if (meatDataNegligibleStatements.includes("3") || meatDataNegligibleStatements.includes("4")) {
                                    statements2 = meatDataNegligibleStatements.filter(val => val === "3" || val === "4");
                                }
                            }
                            response.data.meatData.bseRiskCategoryNegligibleStatements1 = statements1;
                            response.data.meatData.bseRiskCategoryNegligibleStatements2 = statements2;
                        }
                    }
                    SetApiValues(response.data);
                    setReady(true);
                })
                .catch(e => {
                    setReady(true);
                    console.log(e);
                })
        }
    }, [listsReady]);

    //Check for read only permissions
    useEffect(() => {
        setReadOnly(IsReadOnlyPermissions([scopes.productdetail.read], [scopes.productdetail.update, scopes.productdetail.create, scopes.productnongefs.create, scopes.productnongefs.update]));
    }, []);

    return (
        <div>
            <BreadcrumbNav data={breadcrumbData} />
            <h2>{title}</h2>
            {formSubmitted ? (
                <SubmissionPage
                    productId={productId}
                    attestationId={attestationId}
                    isGEFS={isGEFS}
                    errors={errors}
                    title={(editing ? "Edited" : "Completed") + " Composite 8350 Form"}
                    message={`Composite information has been successfully ${(editing ? "updated" : "added")} for ${product.productName} (Product Code: ${product.productCode} EAN: ${product.ean}).`}
                />
            )
                :
                (
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                        validateOnChange={false}
                        validateOnBlur={false}
                        enableReinitialize={true}
                    >
                        {(props) => (

                            <Card border="light" className="bg-white shadow-sm mb-4">
                                <Card.Body>
                                    <Form>
                                        <p>Product: {product.productName} (Product Code: {product.productCode} EAN: {product.ean})</p>
                                        <hr />

                                        <span className='text-warning'>
                                            If using a third party logistics company to store or pack products please list EU approval number(s)
                                        </span>
                                        <br />

                                        <ColdStoreSelect
                                            name="coldStoreEstablishments"
                                            selectedFieldName="coldStoreEstablishments"
                                            setEstablishmentSelected={props.setFieldValue}
                                            selections={props.values?.coldStoreEstablishments}
                                            label="Cold Store(s)"
                                            isMulti={true}
                                            countries={["GB"]}
                                            certificateNumber={[certificateNumber]}
                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                            isRequired={false}
                                            readOnly={readOnly}
                                        />


                                        <label className="mt-3 mb-3 required">What does this composite product contain?</label>
                                        <FormController
                                            control="checkbox"
                                            label="Contains meat products, treated stomachs, bladders and intestines"
                                            name="containsMeat"
                                            readOnly={readOnly || !doesContainMeat}
                                            className={`mb-3 ${!doesContainMeat && 'd-none'}`}
                                        />

                                        {/*////// COMPOSITE - MEAT /////////*/}

                                        {props.values.containsMeat && (
                                            <div className="border-left pl-3 subform">
                                                <h6>Meat products in any quantity except gelatine, collagen and highly refined products</h6>

                                                <FieldArray
                                                    name="meatData.speciesList">
                                                    {arrayHelpers => {
                                                        return (
                                                            <SpeciesTable
                                                                certificateNumber={certificateNumber}
                                                                productType="composite"
                                                                prefix={"meatData"}
                                                                speciesArrayHelpers={arrayHelpers}
                                                                meatGroups={allMeatSpecies}
                                                                countrys={null}
                                                                zonesarray={speciesZonesTable}
                                                                setZonesArray={BuildZoneTable}
                                                                requireSpeciesDetail={true}
                                                                requireTreatments={true}
                                                                requireCountryOfOrigin={true}
                                                                requireEstablishmentCodes={true}
                                                                customCountryOfOriginLabel="Country where meat product was produced"
                                                                customEstablishmentCodesSubLabel="where the meat product was made"
                                                                errors={props.errors}
                                                                readOnly={readOnly}
                                                                productId={productId}
                                                            />
                                                        );
                                                    }}
                                                </FieldArray>

                                                {/*////// END - MEAT TABLES /////////*/}

                                                {/*/////// START - Originates From //////////////*/}
                                                <div className="d-block">
                                                    <label className="mt-4 required">Originates from:</label>
                                                    {props.errors?.meatData?.originatesFrom3 && (props.touched?.meatData?.originatesFrom3 || props.touched?.meatData?.originatesFrom2 || props.touched?.meatData?.originatesFrom1) &&
                                                        (<span className="invalid-feedback d-block mb-3">{props.errors.meatData.originatesFrom3}</span>)
                                                    }

                                                    <ol className="list-clauses" >
                                                        <li>
                                                            <Col className="clause-container" sm={8}>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={compositeLabels.meatOriginatesFromChoice[0].key}
                                                                    name="meatData.originatesFrom1"
                                                                    readOnly={readOnly}
                                                                />
                                                            </Col>
                                                        </li>
                                                        <li>
                                                            <Col className="clause-container" sm={8}>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={compositeLabels.meatOriginatesFromChoice[1].key}
                                                                    name="meatData.originatesFrom2"
                                                                    readOnly={readOnly}
                                                                />
                                                            </Col>
                                                        </li>
                                                        <li>
                                                            <Col className="clause-container" sm={8}>
                                                                <FormController
                                                                    control="checkbox"
                                                                    label={compositeLabels.meatOriginatesFromChoice[2].key}
                                                                    name="meatData.originatesFrom3"
                                                                    readOnly={readOnly}
                                                                />
                                                            </Col>
                                                        </li>
                                                    </ol>
                                                    <hr />
                                                </div>
                                                {/*/////// END - Originates From //////////////*/}


                                                {/*/////// START - BSE Risks //////////////*/}
                                                {(props.values.meatData.containsBOV || props.values.meatData.containsOVI || props.values.meatData.containsRUF || props.values.meatData.containsRUW) && (
                                                    <div className="mt-3">
                                                        <h4>BSE risk category of country of origin (Country where meat product was last processed)</h4>
                                                        {props.values.meatData.BseRiskCategoryNegligible == 1 && (
                                                            <FormController
                                                                control="checkbox"
                                                                label="the country or region of origin is classified in accordance with Commission Decision 2007/453/EC as a country or region posing a <u>negligible BSE risk</u>, and"
                                                                name="meatData.BseRiskCategoryNegligible"
                                                                readOnly={readOnly}
                                                            />
                                                        )}
                                                        {//BSE Risk - Negligible
                                                            props.values.meatData.BseRiskCategoryNegligible && (
                                                                <div className="subform">
                                                                    <FormController
                                                                        control="radioClauses"
                                                                        label=""
                                                                        name="meatData.BseRiskCategoryNegligibleStatements1"
                                                                        options={radioOptions}
                                                                        readOnly={readOnly}
                                                                        inlinelist={true}
                                                                    />
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="meatData.BseRiskCategoryNegligibleStatements2"
                                                                        options={checkboxOptions}
                                                                        readOnly={readOnly}
                                                                        inlinelist={true}
                                                                    />
                                                                </div>
                                                            )}
                                                        {props.values.meatData.BseRiskCategoryControlled == 1 && (
                                                            <FormController
                                                                control="checkbox"
                                                                label="the country or region of origin is classified in accordance with Decision 2007/453/EC as a country or region posing a <u>controlled BSE risk</u>,"
                                                                name="meatData.BseRiskCategoryControlled"
                                                                readOnly={readOnly}
                                                            />
                                                        )}
                                                        {//BSE Risk - Controlled
                                                            props.values.meatData.BseRiskCategoryControlled && (
                                                                <div className="subform">
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="meatData.BseRiskCategoryControlledStatements"
                                                                        options={compositeLabels.BseRiskCategoryControlledStatementsChoice}
                                                                        readOnly={readOnly}
                                                                    />
                                                                    <div className="and-marker" />
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="meatData.BseRiskCategoryControlledCStatements"
                                                                        options={compositeLabels.BseRiskCategoryControlledChoice_C}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )}
                                                        {props.values.meatData.BseRiskCategoryUndetermined == 1 && (
                                                            <FormController
                                                                control="checkbox"
                                                                label="the country or region of origin has not been classified in accordance with Decision 2007/453/EC or is classified as a country or region with an <u>undetermined BSE risk</u>"
                                                                name="meatData.BseRiskCategoryUndetermined"
                                                                readOnly={readOnly}
                                                            />
                                                        )}
                                                        {//BSE Risk - Undetermined
                                                            props.values.meatData.BseRiskCategoryUndetermined && (
                                                                <div className="subform">
                                                                    <FormController
                                                                        control="checkboxClauses"
                                                                        label=""
                                                                        name="meatData.BseRiskCategoryUndeterminedStatements"
                                                                        options={compositeLabels.BseRiskCategoryUndeterminedStatementsChoice}
                                                                        readOnly={readOnly}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                )}
                                                {/*/////// END - BSE Risks //////////////*/}
                                                <hr />
                                            </div>
                                        )}


                                        {/*////// COMPOSITE - DAIRY /////////*/}
                                        <FormController
                                            control="checkbox"
                                            label="Contains dairy"
                                            name="containsDairy"
                                            className={`mb-3 ${!doesContainDairy && 'd-none'}`}
                                            readOnly={readOnly || !doesContainDairy}
                                        />

                                        {props.values.containsDairy && (
                                            <div className="subform border-left pl-3">
                                                <h6>Dairy products or colostrum-based products in any quantity that:</h6>
                                                {props.errors?.dairyData?.dairyA_1 && (props.touched?.dairyData?.dairyA_1 || props.touched?.dairyData?.dairyA_2 || props.touched?.dairyData?.dairyA_3) &&
                                                    (<span className="invalid-feedback d-block mb-3">{props.errors?.dairyData?.dairyA_1}</span>)
                                                }
                                                <ol type="a" className="">
                                                    <li>have been produced:
                                                        <FormController
                                                            control="checkbox"
                                                            label={compositeLabels.dairyAStatementsChoice[0].key}
                                                            name="dairyData.dairyA_1"
                                                            readOnly={readOnly}
                                                            className="mb-3"
                                                        />

                                                        {props.values.dairyData.dairyA_1 &&
                                                            <div className="border-left pl-3 subform">
                                                                <FormController
                                                                    control='multiselect'
                                                                    id='dairyData.dairyACountryOrigin'
                                                                    name='dairyData.dairyACountryOrigin'
                                                                    label="Country"
                                                                    placeholder="Select fields"
                                                                    options={dairyZones}
                                                                    defaultValues={props.values.dairyData?.dairyACountryOrigin}
                                                                    getOptionValue={option => option.id}
                                                                    getOptionLabel={option => option.countryName}
                                                                    value={props.values.dairyData.dairyACountryOrigin}
                                                                    isMulti={true}
                                                                    onChange={(name, value) => {
                                                                        props.setFieldValue("dairyData.dairyACountryOrigin", value);
                                                                    }}
                                                                    onBlur={props.setFieldTouched}
                                                                    touched={props.touched.dairyACountryOrigin}
                                                                    error={props.errors.dairyACountryOrigin}
                                                                    isClearable={true}
                                                                    isrequired="true"
                                                                    backspaceRemovesValue={true}
                                                                    readOnly={readOnly}
                                                                    col={6}
                                                                />
                                                                <hr />
                                                            </div>
                                                        }

                                                        <FormController
                                                            control="checkbox"
                                                            label={compositeLabels.dairyAStatementsChoice[1].key}
                                                            name="dairyData.dairyA_2"
                                                            readOnly={readOnly}
                                                            className="mb-3"
                                                        />

                                                        {props.values.dairyData.dairyA_2 &&
                                                            <div className="border-left pl-3 subform">
                                                                <MultiSelectField
                                                                    id='dairyData.dairyA2CountryOrigin'
                                                                    name='dairyData.dairyA2CountryOrigin'
                                                                    label="Country"
                                                                    placeholder="Select fields"
                                                                    options={dairyVaccZones}
                                                                    defaultValues={props.values.dairyData?.dairyA2CountryOrigin}
                                                                    getOptionValue={option => option.id}
                                                                    getOptionLabel={option => option.countryName}
                                                                    value={props.values.dairyData.dairyA2CountryOrigin}
                                                                    isMulti={true}
                                                                    onChange={(name, value) => {
                                                                        props.setFieldValue("dairyData.dairyA2CountryOrigin", value);
                                                                    }}
                                                                    onBlur={props.setFieldTouched}
                                                                    touched={props.touched.dairyA2CountryOrigin}
                                                                    error={props.errors.dairyA2CountryOrigin}
                                                                    isClearable={true}
                                                                    isrequired="true"
                                                                    backspaceRemovesValue={true}
                                                                    readOnly={readOnly}
                                                                    col={6}
                                                                />
                                                                <hr />
                                                            </div>
                                                        }


                                                        <FormController
                                                            control="checkbox"
                                                            label={compositeLabels.dairyAStatementsChoice[2].key}
                                                            name="dairyData.dairyA_3"
                                                            readOnly={readOnly}
                                                            className="mb-3"
                                                            sublabel="(This includes Northern Ireland (XI) for phytosanitary purposes)"
                                                        />

                                                        <div className="pl-5">
                                                            <DairyEstablishmentSelect
                                                                name="dairyData.dairyAapprovalNumberEstablishments"
                                                                selectedFieldName="dairyData.dairyAapprovalNumberEstablishments"
                                                                setEstablishmentSelected={props.setFieldValue}
                                                                selections={props.values.dairyData?.dairyAapprovalNumberEstablishments}
                                                                label="EU Approval number(s)"
                                                                isMulti={true}
                                                                countries={
                                                                    (props.values.dairyData?.dairyA_1 || props.values.dairyData?.dairyA_2 || props.values.dairyData?.dairyA_3)
                                                                        ? (props.values.dairyData?.dairyA_3
                                                                            ? memberStates.map(x => x.isoCode).concat(
                                                                                props.values.dairyData?.dairyACountryOrigin
                                                                                    .concat(props.values.dairyData?.dairyA2CountryOrigin)
                                                                                    .map(x => x.isoCode)
                                                                                    .map(str => str.replace(/-(0|1|2|3|4|5)/g, ''))
                                                                            )
                                                                            : props.values.dairyData?.dairyACountryOrigin
                                                                                .concat(props.values.dairyData?.dairyA2CountryOrigin)
                                                                                .map(x => x.isoCode)
                                                                                .map(str => str.replace(/-(0|1|2|3|4|5)/g, ''))
                                                                        )
                                                                        : null
                                                                }
                                                                certificateNumber={[certificateNumber]}
                                                                getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                                getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                                isRequired={true}
                                                                readOnly={readOnly}
                                                            />
                                                            <p className="font-italic">
                                                                Approval number of the establishments of origin of the dairy products or the colostrum-based products contained in the composite product authorised at the time of production for export of dairy products or colostrum-based products to the EU
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        originates in:
                                                        <FormController
                                                            control="checkboxClauses"
                                                            label=""
                                                            name="dairyData.dairyBOriginatesIn"
                                                            isrequired="true"
                                                            options={compositeLabels.dairyBOriginatesInChoice}
                                                            readOnly={readOnly}
                                                        />
                                                    </li>
                                                    <li>
                                                        <FormController
                                                            control="checkbox"
                                                            label="Are dairy products made from raw milk"
                                                            name="dairyData.dairyCMilkObtainedFrom"
                                                            readOnly={readOnly}
                                                        />
                                                        {props.errors?.dairyCMilkObtainedFrom && (props.touched?.dairyCMilkObtainedFrom || props.touched?.dairyD) &&
                                                            (<span className="invalid-feedback d-block mb-3">{props.errors.dairyCMilkObtainedFrom}</span>)
                                                        }
                                                        {props.values.dairyData.dairyCMilkObtainedFrom && (
                                                            <>
                                                                <div className='py-3 mx-3'>
                                                                    <label className="mt-3 mb-3 required">Raw milk obtained from (select all that apply):</label>
                                                                    <ul className="list-unstyled mx-3">
                                                                        <li>
                                                                            <FormController
                                                                                control="checkbox"
                                                                                label={compositeLabels.dairyCMilkChoices[0].key}
                                                                                name={compositeLabels.dairyCMilkChoices[0].fieldname}
                                                                            />
                                                                        </li>
                                                                        <li>
                                                                            <FormController
                                                                                control="checkbox"
                                                                                label={compositeLabels.dairyCMilkChoices[1].key}
                                                                                name={compositeLabels.dairyCMilkChoices[1].fieldname}
                                                                            />
                                                                        </li>
                                                                        <li>
                                                                            <FormController
                                                                                control="checkbox"
                                                                                label={compositeLabels.dairyCMilkChoices[2].key}
                                                                                name={compositeLabels.dairyCMilkChoices[2].fieldname}
                                                                            />
                                                                        </li>
                                                                        <li>
                                                                            <FormController
                                                                                control="checkbox"
                                                                                label={compositeLabels.dairyCMilkChoices[3].key}
                                                                                name={compositeLabels.dairyCMilkChoices[3].fieldname}
                                                                            />
                                                                        </li>
                                                                        <li>
                                                                            <FormController
                                                                                control="checkbox"
                                                                                label={compositeLabels.dairyCMilkChoices[4].key}
                                                                                name={compositeLabels.dairyCMilkChoices[4].fieldname}
                                                                            />
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                {props.values.dairyData.dairyCMilkType1
                                                                    || props.values.dairyData.dairyCMilkType2
                                                                    || props.values.dairyData.dairyCMilkType3
                                                                    || props.values.dairyData.dairyCMilkType4
                                                                    || props.values.dairyData.dairyCMilkType5 ? (
                                                                    <div className="clause-container">
                                                                        <FormController
                                                                            control="radioClauses"
                                                                            label=""
                                                                            name="dairyData.dairyCStatements"
                                                                            options={compositeLabels.dairyCStatementsChoice.filter(choice => !(choice.fieldname === "dairyData.dairyCStatements" && choice.value === 2))}
                                                                            readOnly={readOnly}
                                                                        />
                                                                    </div>
                                                                ) :
                                                                    (
                                                                        <div className="clause-container">
                                                                            <FormController
                                                                                control="radioClauses"
                                                                                label=""
                                                                                name="dairyData.dairyCStatements"
                                                                                options={compositeLabels.dairyCStatementsChoice.filter(choice => !(choice.fieldname === "dairyData.dairyCStatements" && choice.value === 1))}
                                                                                readOnly={readOnly}
                                                                            />
                                                                        </div>)}
                                                            </>
                                                        )}
                                                    </li>
                                                    {doesContainColostrum &&
                                                        <li>
                                                            <FormController
                                                                className="clause-container"
                                                                control="checkbox"
                                                                label={compositeLabels.dairyD.key}
                                                                name="dairyData.dairyD"
                                                                readOnly={readOnly}
                                                            />
                                                        </li>
                                                    }
                                                </ol>
                                                <hr />
                                            </div>
                                        )}

                                        {/*////// COMPOSITE - FISHERY /////////*/}

                                        <FormController
                                            control="checkbox"
                                            label="Contains fishery"
                                            name="containsFishery"
                                            className={`mb-3 ${!doesContainFishery && 'd-none'}`}
                                            readOnly={readOnly || !doesContainFishery}
                                        />

                                        {props.values.containsFishery && (
                                            <div className="subform border-left pl-3">
                                                <h6>Fishery products originating from:</h6>
                                                <MultiSelectField
                                                    id='fisheryCountryOrigin'
                                                    name='fisheryCountryOrigin'
                                                    label="Fishery Country of Origin"
                                                    placeholder="Select fields"
                                                    options={countrys}
                                                    defaultValues={props.values?.fisheryCountryOrigin}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.fisheryCountryOrigin}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.fisheryCountryOrigin}
                                                    error={props.errors.fisheryCountryOrigin}
                                                    isClearable={true}
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />

                                                <label className="mt-3 mb-3 ">Type of fishery (if product contains fishery)</label>

                                                <FormController
                                                    control="checkbox"
                                                    label="Aquaculture"
                                                    name="fisheryAquaculture"
                                                    readOnly={readOnly}
                                                />

                                                <FormController
                                                    control="checkbox"
                                                    label="Wild Origin"
                                                    name="fisheryWildOrigin"
                                                    readOnly={readOnly}
                                                />
                                                <FisherySelect
                                                    name="fisheryEstablishmentCodeEstablishments"
                                                    selectedFieldName="fisheryEstablishmentCodeEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.fisheryEstablishmentCodeEstablishments}
                                                    label="Establishment code(s)"
                                                    isMulti={true}
                                                    countries={props.values?.fisheryCountryOrigin.map(x => x.isoCode)}
                                                    certificateNumber={certificateNumber}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={false}
                                                    readOnly={readOnly}
                                                />

                                                <hr />
                                                {/*////// COMPOSITE - Bivalve Molluscs /////////*/}

                                                <h6>Bivalve Molluscs products originating from:</h6>
                                                <MultiSelectField
                                                    id='bivalveMolluscsCountryOrigin'
                                                    name='bivalveMolluscsCountryOrigin'
                                                    label="Bivalve Molluscs Country of Origin"
                                                    placeholder="Select fields"
                                                    options={bivalveMolluscsZones}
                                                    defaultValues={props.values?.bivalveMolluscsCountryOrigin}
                                                    getOptionValue={option => option.id}
                                                    getOptionLabel={option => option.displayName}
                                                    value={props.values.bivalveMolluscsCountryOrigin}
                                                    isMulti={true}
                                                    onChange={props.setFieldValue}
                                                    onBlur={props.setFieldTouched}
                                                    touched={props.touched.bivalveMolluscsCountryOrigin}
                                                    error={props.errors.bivalveMolluscsCountryOrigin}
                                                    isClearable={true}
                                                    backspaceRemovesValue={true}
                                                    readOnly={readOnly}
                                                />

                                                <label className="mt-3 mb-3">Type of Bivalve Molluscs (if product contains Bivalve Molluscs)</label>

                                                <FormController
                                                    control="checkbox"
                                                    label="Aquaculture"
                                                    name="bivalveMolluscsAquaculture"
                                                    readOnly={readOnly}
                                                />

                                                <FormController
                                                    control="checkbox"
                                                    label="Wild Origin"
                                                    name="bivalveMolluscsWildOrigin"
                                                    readOnly={readOnly}
                                                />

                                                <BivalveSelect
                                                    name="bivalveMolluscsEstablishmentCodeEstablishments"
                                                    selectedFieldName="bivalveMolluscsEstablishmentCodeEstablishments"
                                                    setEstablishmentSelected={props.setFieldValue}
                                                    selections={props.values.bivalveMolluscsEstablishmentCodeEstablishments}
                                                    label="Establishment code(s)"
                                                    isMulti={true}
                                                    countries={props.values?.bivalveMolluscsCountryOrigin.map(x => x.isoCode)}
                                                    certificateNumber={certificateNumber}
                                                    getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                    getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                    isRequired={false}
                                                    readOnly={readOnly}
                                                />

                                                <hr />
                                            </div>
                                        )}

                                        {/*////// COMPOSITE - EGG /////////*/}
                                        <FormController
                                            control="checkbox"
                                            label="Contains egg"
                                            name="containsEgg"
                                            readOnly={readOnly || !doesContainEgg}
                                            className={`mb-3 ${!doesContainEgg && 'd-none'}`}
                                        />
                                        {props.values.containsEgg && (
                                            <div className="border-left subform">
                                                <label className="mt-3 mb-3 required">Originates from</label>
                                                <FormController
                                                    control="checkbox"
                                                    label={compositeLabels.eggOriginatesFromChoiceForm[0].key}
                                                    name="eggData.eggOriginatesFrom1"
                                                    readOnly={readOnly}

                                                />

                                                {props.values.eggData.eggOriginatesFrom1 === true && (
                                                    <>
                                                        <div className="border-left pl-3 subform">
                                                            <MultiSelectField
                                                                id='eggCountryOrigin'
                                                                name='eggData.eggCountryOrigin'
                                                                label="Zone"
                                                                className="mb-3"
                                                                placeholder="Select fields"
                                                                options={eggZones}
                                                                defaultValues={props.values?.eggData.eggCountryOrigin}
                                                                getOptionValue={option => option.id}
                                                                getOptionLabel={option => option.regionName}
                                                                value={props.values.eggData.eggCountryOrigin}
                                                                isMulti={true}
                                                                onChange={props.setFieldValue}
                                                                onBlur={props.setFieldTouched}
                                                                touched={props.touched.eggCountryOrigin}
                                                                error={props.errors.eggCountryOrigin}
                                                                isClearable={true}
                                                                isrequired={true}
                                                                backspaceRemovesValue={true}
                                                                readOnly={readOnly}
                                                                col={6}
                                                            />
                                                            <hr />
                                                        </div>
                                                    </>
                                                )}
                                                <FormController
                                                    control="checkbox"
                                                    label={compositeLabels.eggOriginatesFromChoiceForm[1].key}
                                                    name="eggData.eggOriginatesFrom2"
                                                    readOnly={readOnly}
                                                />
                                                {(props.values.eggData.eggOriginatesFrom1 === true || props.values.eggData.eggOriginatesFrom2 === true) && (
                                                    <>
                                                        <EggEstablishmentSelect
                                                            name="eggData.eggEstablishments"
                                                            selectedFieldName="eggData.eggEstablishments"
                                                            setEstablishmentSelected={props.setFieldValue}
                                                            selections={props.values.eggEstablishments}
                                                            label="Egg Processing approved establishment(s)"
                                                            isMulti={true}
                                                            countries={props.values.eggData?.eggOriginatesFrom1 || props.values.eggData?.eggOriginatesFrom2 ? [...(props.values.eggData?.eggOriginatesFrom1 ? props.values.eggData.eggCountryOrigin?.map(x => x.isoCode.replace(/-(0|1|2|3|4|5)/g, '')) : []), ...(props.values.eggData?.eggOriginatesFrom2 ? memberStates.map(x => x.isoCode) : [])] : null}
                                                            certificateNumber={certificateNumber}
                                                            getOptionValue={option => option.approvalNumber ? option.approvalNumber : ""}
                                                            getOptionLabel={option => option.name ? option.name + " (" + option.approvalNumber + ")" : ""}
                                                            isRequired={true}
                                                        />
                                                    </>
                                                )}
                                                <label className="pt-3 pb-1">
                                                    were produced from eggs coming from an establishment which satisfies the requirements of Annex III, Section X, to Regulation (EC) No 853/2004 and:
                                                </label>
                                                <br></br>
                                                <FormController
                                                    control="radioClauses"
                                                    label="Section A "
                                                    name="eggData.eggStatementsA"
                                                    options={compositeLabels.eggStatementsChoicesA}
                                                    readOnly={readOnly}
                                                />
                                                <FormController
                                                    control="radioClauses"
                                                    label="Section B  "
                                                    name="eggData.eggStatementsB"
                                                    options={compositeLabels.eggStatementsChoicesB}
                                                    readOnly={readOnly}
                                                />
                                            </div>
                                        )}

                                        <div className="form-group">
                                            <hr />

                                            {Object.keys(props.errors).length > 0 &&
                                                <Alert
                                                    className="mt-2"
                                                    variant="danger"
                                                    size="sm"
                                                >
                                                    <div className="d-flex justify-content-start">
                                                        <FontAwesomeIcon icon={faBullhorn} className="me-3" />
                                                        Some errors were found, check the form above for any missing or incomplete information.
                                                    </div>
                                                </Alert>
                                            }

                                            {/*////// SUBMIT /////////*/}
                                            {(props.values.containsEgg || props.values.containsMeat || props.values.containsFishery || props.values.containsDairy) ? (
                                                <Button type="submit" disabled={isSubmitting || readOnly}>{isSubmitting ? "Please wait..." : "Save"}</Button>
                                            ) : (
                                                <Button type="button" disabled={true}>{"Save"}</Button>
                                            )
                                            }
                                            <BackButton
                                                isGEFS={isGEFS}
                                                productId={productId}
                                                attestationId={attestationId}
                                            />
                                        </div>
                                        <ChangeFormValues />
                                    </Form>
                                </Card.Body>
                            </Card>
                        )}
                    </Formik >
                )}
        </div>
    );
};

export default Composite8350AddPage;